import React from "react";

const Info = () => {
  return (
    <div className="info">
      <br />
      <h1>Välkommen till Tipsrundan!</h1>
      <strong>Så här spelar du:</strong>
      <ol>
        <li>
          <strong>Välj en runda</strong><br />Starta med att välja en tipsrunda från
          listan. Vi har rundor för alla åldrar!
        </li>
        <li>
          <strong>Lås upp frågor</strong><br />Hitta tavlor runtom i båten och
          använd koden för att låsa upp din nästa fråga.
        </li>
        <li>
          <strong>Svara på frågorna</strong><br />Besvara frågorna en efter en. När
          du är klar får du se hur många rätt du har.
        </li>
        <li>
          <strong>Fel svar?</strong><br />Om du svarar fel kan du alltid gå tillbaka
          och testa igen.
        </li>
        <li>
          <strong>Klart!</strong><br />När du har svarat rätt på alla frågor, gå till
          informationen för att hämta ditt pris!
        </li>
      </ol>
      <p>
        <strong>Fortsätt eller börja om:</strong><br />Dina svar sparas i två timmar,
        så du kan fortsätta där du slutade även om du tar en paus.
      </p>
      <p>
        <strong>Lycka till och ha så kul!</strong>
      </p>
    </div>
  );
};

export default Info;
