import React, { useState } from "react";
import { apiUrl } from "../../services/api";
import axios from "axios";
import Loader from "../Loader/Loader";

const API_ENDPOINT = apiUrl(`homepage-image`);
const imageUrl = apiUrl(`homepage-image`);

const ChooseImage = ({
  changeDashboard,
  changeChangePassword,
  changeChooseImage,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(`${imageUrl}?refreshKey=0`); // Initialize with old image URL
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0); // State to force re-render
  const [isLoading, setIsLoading] = useState(false); // State for loading status

  const handleGoBack = () => {
    changeDashboard(true);
    changeChangePassword(false);
    changeChooseImage(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setSuccessMessage(null);
    setError(null);

    // Display image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(`${imageUrl}?refreshKey=${refreshKey}`); // Show old image preview if no file selected
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;

    setIsLoading(true); // Start loading process

    const formData = new FormData();
    formData.append("image", selectedImage);

    const loggedInUser = JSON.parse(localStorage.getItem("LoggedInUser")) ?? {};
    const accessToken = loggedInUser.accessToken;

    try {
      await axios.put(API_ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSuccessMessage("Bilden har laddats upp.");
      setError(null);
      // Update key to force re-render and trigger image reload
      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Fel vid uppladdning av bild:", error);
      setError("Fel vid uppladdning av bild.");
      setSuccessMessage(null);
    } finally {
      setIsLoading(false); // End loading process
    }
  };

  return (
    <>
      <div className="button-container">
        <button className="go-back-button" onClick={handleGoBack}>
          Tillbaka
        </button>
      </div>
      <div className="center-container">
        <div className="user-list-container">
          <h2>Byt bild på hemskärmen</h2>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: "400px" }} />
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}
              {error && <p style={{ color: "red" }}>{error}</p>}
              <input
                type="file"
                onChange={handleImageChange}
                accept=".jpg, .png, .gif, .svg"
              />
              <span>Bildformat som stöds: .jpg, .png, .gif, .svg</span>
              <span>Maxstorlek: 5 MB</span>
              <button className="large-button" onClick={handleImageUpload}>
                Byt bild
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChooseImage;
