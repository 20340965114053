import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { slide as Menu } from "react-burger-menu";

const LoginHeader = ({
  logout,
  changeDashboard,
  changeShowForm,
  changeChangePassword,
  changeShowUsersAsAdmin,
}) => {
  const handleChangePassword = () => {
    changeDashboard(false);
    changeShowForm(false);
    changeChangePassword(true);
  };

  const [username, setUsername] = useState(""); // State to hold the username
  useEffect(() => {
    const storedData = localStorage.getItem("LoggedInUser");
    if (storedData) {
      const token = JSON.parse(storedData).accessToken;
      // Decode the token to get user information
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        // Extract the username from the decoded token
        const username = decodedToken.username;
        setUsername(username);
      }
    }
  }, []);

  const handleUsersAsAdmin = () => {
    changeDashboard(false);
    changeShowForm(false);
    changeChangePassword(false);
    changeShowUsersAsAdmin(true);
  };

  return (
    <div>
      <Menu right>
        <button className="menu-item" onClick={logout}>
          Logga ut
        </button>
        <button className="menu-item" onClick={handleChangePassword}>
          Ändra lösenord
        </button>
        {username === "Admin" && (
          <button className="menu-item" onClick={handleUsersAsAdmin}>
            Hantera användare
          </button>
        )}
      </Menu>
      <header className="wave-bottom dashboard-header">
        <h1 className="welcome-text">Du är inloggad som {username}</h1>
      </header>
    </div>
  );
};

export default LoginHeader;
