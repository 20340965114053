import React from "react";

const FormInputField = ({ label, value, onChange, error, type = "text", placeholder = "" }) => (
    <div className="input-container">
        <label htmlFor="label">
            {label}:
        </label>
        <input
            id={label}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
        />
        {error && <div className="input-error-text">{error}</div>}

    </div>
);

export default FormInputField;
