import React, { useState, useEffect } from "react";

const Question = ({ data, onChange }) => {
  const [expandQuestions, setExpandQuestion] = useState(true);
  const [expandAnswers, setExpandAnswers] = useState(true);
  const [questions, setQuestions] = useState(data.Questions || []);

  useEffect(() => {
    let sortedQuestions = (data.Questions || []).sort(
      (a, b) => a.number - b.number
    );
    setQuestions(sortedQuestions);
  }, [data.Questions]);

  const handleChange = (
    type,
    numberOrQuestionNumber,
    valueOrAnswerId,
    value
  ) => {
    if (type === "question") {
      const updatedQuestions = data.Questions.map((question) => {
        if (question.number === numberOrQuestionNumber) {
          return { ...question, question: value };
        } else {
          return question;
        }
      });
      setQuestions(updatedQuestions);
      onChange("Questions", updatedQuestions);
    } else if (type === "answer") {
      const updatedQuestions = questions.map((question) => {
        if (question.number === numberOrQuestionNumber) {
          const updatedAnswers = question.Answers.map((answer) => {
            if (answer.id === valueOrAnswerId) {
              return { ...answer, answer: value };
            }
            return answer;
          });
          return { ...question, Answers: updatedAnswers };
        }
        return question;
      });
      setQuestions(updatedQuestions);
      onChange("Questions", updatedQuestions);
    } else if (type === "radio") {
      const updatedQuestions = questions.map((question) => {
        if (question.number === numberOrQuestionNumber) {
          const updatedAnswers = question.Answers.map((answer) => {
            if (answer.id === valueOrAnswerId) {
              return { ...answer, isCorrect: value };
            } else {
              return { ...answer, isCorrect: false };
            }
          });
          return { ...question, Answers: updatedAnswers };
        }
        return question;
      });
      setQuestions(updatedQuestions);
      onChange("Questions", updatedQuestions);
    }
  };

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const addQuestion = () => {
    const newQuestionId = questions.length + 1;
    const updatedQuestions = [
      ...questions,
      {
        number: newQuestionId,
        question: "",
        Answers: [
          { id: generateRandomId(), answer: "" },
          { id: generateRandomId(), answer: "" },
          { id: generateRandomId(), answer: "" },
        ],
      },
    ];
    setQuestions(updatedQuestions);
    onChange("Questions", updatedQuestions);
  };

  const removeQuestion = (number) => {
    const updatedQuestions = questions.filter(
      (question) => question.number !== number
    );
    setQuestions(updatedQuestions);
    onChange("Questions", updatedQuestions);
  };

  const toggleQuestion = () => {
    setExpandQuestion(!expandQuestions);
  };

  const toggleAnswer = () => {
    setExpandAnswers(!expandAnswers);
  };

  return (
    <div>
      <div>
        <h3 onClick={toggleQuestion}>{expandQuestions ? ">" : "v"} Frågor</h3>
        {!expandQuestions && (
          <div className="question-container">
            {questions.map((question, index) => (
              <div className="input-container" key={question.number}>
                <label>{`Fråga ${question.number}`}</label>
                <div className="input-row">
                  <input
                    type="text"
                    value={question.question || ""}
                    onChange={(e) =>
                      handleChange(
                        "question",
                        question.number,
                        null,
                        e.target.value
                      )
                    }
                  />
                  {index === questions.length - 1 && index !== 0 && (
                    <button
                      className="delete-button"
                      onClick={() => removeQuestion(question.number)}
                    >
                      Radera
                    </button>
                  )}
                </div>
              </div>
            ))}
            <button onClick={addQuestion}> + Lägg till fråga</button>
          </div>
        )}
      </div>
      <div>
        <h3 onClick={toggleAnswer}>{expandAnswers ? ">" : "v"} Svar</h3>
        {!expandAnswers && (
          <div className="question-container">
            {questions.map((question) => (
              <div className="input-container" key={question.number}>
                <label>{`Fråga ${question.number}`}</label>
                <div>
                  {question.Answers.map((answer, index) => (
                    <div className="answer-row" key={"new" + index}>
                      <input
                        type="text"
                        value={answer.answer}
                        onChange={(e) =>
                          handleChange(
                            "answer",
                            question.number,
                            answer.id,
                            e.target.value
                          )
                        }
                      />
                      <input
                        type="radio"
                        checked={answer.isCorrect}
                        onChange={(e) =>
                          handleChange(
                            "radio",
                            question.number,
                            answer.id,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Question;
