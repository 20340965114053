import React, { useState, useEffect } from 'react';
import { getActiveQuizzes, apiUrl } from '../../services/api';
import Loader from '../Loader/Loader';

const QuizCategorySelector = ({ onSelect }) => {
    const [quizCategories, setQuizCategories] = useState([]);

    const imageUrl = apiUrl(`homepage-image`);

    useEffect(() => {
        // Fetch categories when the component mounts
        const fetchCategories = async () => {
            try {
                const categories = await getActiveQuizzes();
                setQuizCategories(categories);
            } catch (error) {
                console.error('Error fetching quiz categories:', error);
            }
        };

        fetchCategories();
    }, []);

    // Guard clause for rendering while quizCategories are loading
    if (!Array.isArray(quizCategories) || quizCategories.length === 0) {
        return <Loader />;
    }

    return (
        <>
            <h2>Välkommen till Eckerö Linjens tipsrunda!</h2>
            <img
                id="question-image"
                src={imageUrl}
                alt="Tipsrundan"
                onError={(e) => e.target.style.display = 'none'}
            />
            <h3 style={{ marginBottom: "4px" }}>Vilken tipsrunda vill du gå?</h3>
            <div id="category-container">
                <ul className='category-list'>
                    {quizCategories.map(category => (
                        <li
                            key={category.id}
                            onClick={() => onSelect(category.id)}
                            className={`category-box`}
                        >
                            {category.name} {category.ageCategory ? `${category.ageCategory} år` : ''}
                        </li>
                    ))}
                </ul>
            </div>

        </>
    );
};

export default QuizCategorySelector;
