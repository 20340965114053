import React, { useState, useRef } from 'react';
import ActionButton from './ActionButton';

const SecretCodeInput = ({ onCodeSubmit, correctCode }) => {
    const [inputValue, setInputValue] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const timeoutRef = useRef(null);


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmitClick = () => {

        const correctCodeString = correctCode.toString();

        // Clear the existing timeout if it exists
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null; // Reset the ref after clearing
        }

        if (inputValue !== correctCodeString) {
            // Trigger style change
            setIsIncorrect(true);

            timeoutRef.current = setTimeout(() => {
                setIsIncorrect(false);
            }, 600);

        } else {
            onCodeSubmit();
        }
    };

    return (
        <>
            <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                id="secret-number-input"
                className={isIncorrect ? 'incorrect shake' : ''}
                name="secret-number"
                size="5"
                maxLength="4"
                value={inputValue}
                onChange={handleInputChange}
                autoComplete="off"
            />
            <ActionButton onClick={handleSubmitClick} disabled={inputValue === ''}>Skicka</ActionButton>
        </>
    );
};

export default SecretCodeInput;