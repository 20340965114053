import React, { useState } from "react";
import axios from "axios";
import FormInputField from "./FormInputField";
import { apiUrl } from '../../services/api';

const UserEditPasswordAdmin = ({ id, username, onGoBack }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [responseError, setResponseError] = useState("");

    const validateForm = () => {
        const errors = {};

        if (!password) {
            errors.password = "Lösenordet kan inte vara tomt.";
        } else if (password.length < 8) {
            errors.password = "Minst 8 tecken.";
        } else if (password.length > 128) {
            errors.password = "Lösenordet får vara max 128 tecken.";
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = "Lösenorden stämmer inte överens.";
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const loggedInUser = JSON.parse(localStorage.getItem("LoggedInUser")) ?? {};
        const accessToken = loggedInUser.accessToken;

        try {
            await axios.put(apiUrl(`users/change-password/admin`), {
                newPassword: password,
                userId: id,
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            setSuccessMessage("Ändring av lösenord lyckades.");
        } catch (error) {
            console.error("Ett fel inträffade:", error);
            setResponseError("Ett fel inträffade. Försök igen senare.");
        }
    };

    return (
        <>
            <div className="button-container">
                <button className="go-back-button" onClick={onGoBack}>
                    Tillbaka
                </button>
            </div>
            <div className="center-container">
                <div className="form-container user-form-container">
                    {!responseError && !successMessage && (
                        <>
                            <h2>Ändra lösenord för {username}</h2>
                            <FormInputField
                                label="Nytt lösenord"
                                value={password}
                                onChange={setPassword}
                                error={formErrors.password}
                                type="password"
                            />
                            <FormInputField
                                label="Upprepa nytt lösenord"
                                value={confirmPassword}
                                onChange={setConfirmPassword}
                                error={formErrors.confirmPassword}
                                type="password"
                            />
                            <button className="large-button" type="submit" onClick={handleSubmit}>
                                Ändra lösenord
                            </button>
                        </>
                    )}
                    {responseError && <div className="error-message">{responseError}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                </div>
            </div>
        </>
    );
};

export default UserEditPasswordAdmin;
