import React from 'react';
import ActionButton from './ActionButton';
import { apiUrl } from '../../services/api';

const ResumePrompt = ({ onContinue, onCancel }) => {

    const imageUrl = apiUrl(`homepage-image`);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img
                id="question-image"
                src={imageUrl}
                alt="Tipsrundan"
                onError={(e) => e.target.style.display = 'none'}
            />
            <h2 style={{ marginBottom: '20px' }}>Vill du fortsätta från där du slutade senast?</h2>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <ActionButton onClick={onContinue} disabled={false}>
                    Fortsätt
                </ActionButton>
                <ActionButton onClick={onCancel} disabled={false}>
                    Börja om
                </ActionButton>
            </div>
        </div>
    );
};

export default ResumePrompt;
