import { useState, React, useRef } from "react";

import AdminService from "../../services/admin-api";

const Login = ({ changeToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  //To Do: Validate
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const data = await AdminService.signIn(username, password); // Pass username and password
      changeToken();
    } catch (error) {
      console.log(error);
      if (error.message === "Request failed with status code 404") {
        setErrorMessage("Du har fel inloggningsuppgifter, försök igen.");
      } else {
        setErrorMessage("Någonting gick fel, försök igen");
      }
    }
  };

  return (
    <div className="log-in-container">
      <form onSubmit={handleSignIn}>
        <h1>Logga in</h1>
        <div>
          <label htmlFor="username" />
          <input
            placeholder="Användarnamn"
            type="text"
            id="username"
            name="username"
            onChange={onChangeUsername}
          />
        </div>
        <div>
          <label htmlFor="password" />
          <input
            placeholder="Lösenord"
            type="password"
            id="password"
            name="password"
            onChange={onChangePassword}
          />
        </div>
        <button className="log-in-button" type="submit">
          Logga in
        </button>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default Login;
