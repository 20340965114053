import React from 'react';

const Answer = ({ answerId, number, text, selected, onSelectAnswer }) => {
  return (
    <div className={`answer-box ${selected ? 'selected' : ''}`} onClick={() => onSelectAnswer(answerId)}>
      <div className="number-container">
        <div className="circle">
          <p>{number}</p>
        </div>
      </div>
      <div className="answer-text">{text}</div>
    </div>
  );
};

export default Answer;