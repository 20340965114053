import React from "react";

const ExpiredSession = ({ handleStayLoggedIn, logout }) => {
  return (
    <div className="log-in-container">
      <p>Din session har tyvärr gått ut, vill du logga in igen?</p>
      <div className="popup-button-container">
        <button className="log-in-button" onClick={handleStayLoggedIn}>Logga in</button>
      </div>
    </div>
  );
};

export default ExpiredSession;
