import React from 'react';
import ActionButton from './ActionButton';

const Modal = ({ onCloseModal, children }) => {

    return (
        <>
            <div className="modal-backdrop" onClick={onCloseModal}></div>
            <div className="modal-content-wrapper">
                <div className="modal-content">
                    {children}
                </div>
                <div style={{ textAlign: "center" }}>
                    <ActionButton onClick={onCloseModal}>Stäng</ActionButton>
                    <br />
                </div>
            </div >
        </>
    );
};

export default Modal;