import React, { useState, useEffect } from "react";
import LocationForm from "./LocationForm";
import Loader from "../Loader/Loader"
import AdminService from "../../services/admin-api";
import axios from "axios"; // Assuming you're using axios for API calls

const LocationManager = ({ handleGoBack }) => {
    const [locations, setLocations] = useState([]);
    const [editingLocationId, setEditingLocationId] = useState(null); // ID of the location being edited
    const [isLoading, setIsLoading] = useState(true); // Indicates if locations are being loaded
    const [isAddingNew, setIsAddingNew] = useState(false); // True if adding new location

    useEffect(() => {
        // Fetch initial locations data when the component mounts
        fetchLocations();
    }, []);

    const fetchLocations = async () => {
        try {
            const data = await AdminService.getLocations();
            setLocations(data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching locations:", error);
            //ToDo: Handle error
        }
    };

    const handleEdit = (locationId) => {
        setIsAddingNew(false);
        setEditingLocationId(locationId);
    };

    const handleCancel = () => {
        setIsAddingNew(false);
        setEditingLocationId(null);
    };

    const handleAddNew = () => {
        setIsAddingNew(true);
        setEditingLocationId(null);
    };

    const handleDelete = async (locationId) => {
        try {
            const data = await AdminService.deleteLocation(locationId);
            fetchLocations();
        } catch (error) {
            console.error("Error deleting location:", error);
            //ToDo: Handle error
        }
    };

    const handleSubmit = async (locationData) => {
        try {
            if (isAddingNew) {
                //POST
                const data = await AdminService.createLocation(locationData, locations.length + 1);
            } else {
                //PUT
                const data = await AdminService.updateLocation(editingLocationId, locationData);
            }
            fetchLocations();
            setEditingLocationId(null);
            setIsAddingNew(null);
        } catch (error) {
            console.error("Error submitting location:", error);
            //ToDo: Handle error
        }
    };

    const isAnyLocationEditing = isAddingNew || editingLocationId !== null;

    return (
        <>
            <div className="button-container">
                <button className="go-back-button" onClick={handleGoBack}>
                    Tillbaka
                </button>
            </div>
            <div className="center-container">
                <h2>Ändra och lägg till platser för frågor</h2>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="location-list-container">
                        {locations.length === 0 ? (
                            <p>Det finns inte några platser.</p>
                        ) : (
                            locations.map((location, index) => (
                                <LocationForm
                                    key={location.id}
                                    onEdit={() => handleEdit(location.id)}
                                    onSubmit={handleSubmit}
                                    onCancel={handleCancel}
                                    onDelete={() => handleDelete(location.id)}
                                    isAnyLocationEditing={isAnyLocationEditing}
                                    isEditingThis={location.id === editingLocationId}
                                    initialLocationData={location} // Use existing location data
                                    isLast={index === locations.length - 1} // Check if it's the last location
                                />
                            ))
                        )}
                        {isAddingNew && (
                            <LocationForm
                                key="newLocation"
                                onSubmit={handleSubmit}
                                onCancel={handleCancel}
                                isAnyLocationEditing={true}
                                isEditingThis={true}
                                initialLocationData={{ number: locations.length + 1, secretCode: "", hint: "" }} // New location with default values
                                isLast={true}
                            />
                        )}
                        <div className="center-container">
                            <button className="small-button" onClick={handleAddNew} disabled={isAnyLocationEditing}>
                                + Lägg till ny plats
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LocationManager;