import config from '../config';

export const apiUrl = (...path) => [config.api.baseUrl, ...path.map(p => p.replace(/^\//, ''))].join('/');

const fetchData = async (endpoint) => {
    try {
        const response = await fetch(apiUrl(endpoint));
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error.message);
        throw error;
    }
};

export const getActiveQuizzes = () => fetchData('quizzes/active');
export const getQuestionsByQuizId = (quizId) => fetchData(`questions/${quizId}`);
export const getAnswersByQuestionId = (questionId) => fetchData(`answers/${questionId}`);
export const getAllQuizData = (quizId) => fetchData(`quizzes/${quizId}/alldata`)
export const getLocationData = () => fetchData(`locations`);
