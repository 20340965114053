import React, { useState } from 'react';
import FormInputField from './FormInputField';

const LocationForm = ({
    onSubmit,
    onCancel,
    onDelete,
    initialLocationData,
    onEdit,
    isAnyLocationEditing,
    isEditingThis,
    isLast,
}) => {
    const [locationData, setLocationData] = useState(initialLocationData || { passcode: '', hint: '' });
    const [initialLocationDataState, setInitialLocationDataState] = useState(initialLocationData);
    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!locationData.passcode) {
            errors.passcode = "Koden kan inte vara tom.";
        } else if (!/^\d+$/.test(locationData.passcode)) {
            errors.passcode = "Koden måste vara numerisk.";
        } else if (locationData.passcode.length > 4) {
            errors.passcode = "Koden kan inte vara över 4 tecken.";
        }

        if (!locationData.hint) {
            errors.hint = "Ledtråden kan inte vara tom.";
        } else if (locationData.hint.length > 300) {
            errors.hint = "Ledtråden får inte vara längre än 300 tecken.";
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    const handleSave = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        onSubmit(locationData);
    };

    const handleCancel = () => {
        setLocationData(initialLocationDataState);
        setFormErrors({});
        onCancel();
    };

    const handleDelete = () => {
        setLocationData(initialLocationDataState);
        onDelete(locationData.id);
    };

    return (
        <div className={`location-container ${isEditingThis ? 'active-edit' : ''}`}>
            <div className="location-data-container">
                <div className="location-number">{locationData.number}</div>
                <div className="location-text-container">
                    {isEditingThis ? (
                        <>
                            <FormInputField
                                label="Kod"
                                name="passcode"
                                value={locationData.passcode}
                                placeholder="ex. 1234"
                                onChange={(newValue) => setLocationData({ ...locationData, passcode: newValue })}
                                error={formErrors.passcode}
                            />
                            <FormInputField
                                label="Denna fråga finns"
                                name="hint"
                                value={locationData.hint}
                                placeholder="ex. där det är mycket fönster och ventiler!"
                                onChange={(newValue) => setLocationData({ ...locationData, hint: newValue })}
                                error={formErrors.hint}
                            />
                        </>
                    ) : (
                        <>
                            <span><b>Kod:</b> {locationData.passcode}</span>
                            <span><b>Denna fråga finns:</b> {locationData.hint}</span>
                        </>
                    )}
                </div>
            </div>
            <div className='button-row-right'>
                {isEditingThis ? (
                    <>
                        <button className="outline-button" onClick={handleCancel}>Avbryt</button>
                        <button className="small-button" onClick={handleSave}>Spara</button>
                    </>
                ) : (
                    <>
                        <button className="small-button" disabled={isAnyLocationEditing} onClick={onEdit}>Ändra</button>
                        {isLast && (
                            <button className="small-button" disabled={isAnyLocationEditing} onClick={handleDelete}>Radera</button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default LocationForm;
