import React, { useState, useEffect } from 'react';
import Header from '../components/Quiz/Header';
import Footer from '../components/Quiz/Footer';
import Question from '../components/Quiz/Question';
import QuestionUnlockView from '../components/Quiz/QuestionUnlockView';
import QuizCategorySelector from '../components/Quiz/QuizCategorySelector';
import Result from '../components/Quiz/Result';
import Loader from '../components/Loader/Loader';
import ResumePrompt from '../components/Quiz/ResumePrompt';
import Info from '../components/Quiz/Info';
import Modal from '../components/Quiz/Modal';
import useModal from '../hooks/useModal';
import InfoButton from '../components/Quiz/InfoButton';
import { getLocationData, getAllQuizData } from '../services/api';

const Quiz = () => {
    const [quizData, setQuizData] = useState({});
    const [locations, setLocations] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentQuestionIsUnlocked, setCurrentQuestionIsUnlocked] = useState(false);
    const [userAnswers, setUserAnswers] = useState([]);
    const [stateDataLoaded, setStateDataLoaded] = useState(false);
    const [showResumePrompt, setShowResumePrompt] = useState(false);
    const { isShowing, toggleModal } = useModal();

    // Fetch location data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getLocationData();
                setLocations(data);
            } catch (error) {
                console.error('Error fetching location data:', error);
                // ToDo: Handle error?
            }
        };

        fetchData();
    }, []);

    // Fetch quiz data when selectedCategory changes
    useEffect(() => {
        // Fetch quiz data only if selectedCategory exists
        if (selectedCategory) {
            const fetchData = async () => {
                try {
                    // Fetch quiz data
                    const quizData = await getAllQuizData(selectedCategory);
                    setQuizData(quizData);
                } catch (error) {
                    console.error('Error fetching quiz data:', error);
                    // ToDo: Handle error?
                }
            };

            fetchData();
        }
    }, [selectedCategory]);

    // Save game state to local storage
    useEffect(() => {
        if (selectedCategory) {
            const gameState = {
                userAnswers,
                selectedCategory,
                currentQuestionIsUnlocked,
                timestamp: Date.now(),
            };
            localStorage.setItem('quizGameState', JSON.stringify(gameState));
        }
    }, [userAnswers, selectedCategory, currentQuestionIsUnlocked]);

    // On component mount, check if recent game state from local storage exists
    useEffect(() => {
        const savedGameState = localStorage.getItem('quizGameState');

        if (savedGameState) {
            const parsedGameState = JSON.parse(savedGameState);

            // If game state is newer than 2 hours
            if (parsedGameState.timestamp &&
                (Date.now() - parsedGameState.timestamp) < 2 * 60 * 60 * 1000) {
                setShowResumePrompt(true);
                return;
            }
        }
        setStateDataLoaded(true);
    }, []);


    // Helper function to find the index of the next unanswered question
    const findNextUnansweredIndex = (userAnswers, questions) => {
        return questions.findIndex(question => !userAnswers.some(answer => answer.questionId === question.id));
    };

    // ToDo: fix issue with not scrolling to top when parent element is 100vh
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const currentQuestionIndex = quizData && quizData.Questions ? [findNextUnansweredIndex(userAnswers, quizData.Questions)] : undefined;

    // ---------------------- Event Handlers ---------------------

    const handleStartOver = () => {
        setStateDataLoaded(true);
        setSelectedCategory(null);
        setUserAnswers([]);
        setShowResumePrompt(false);
        scrollToTop();
    };

    const handleContinueFromLocalStorage = () => {
        const savedGameState = localStorage.getItem('quizGameState');
        const parsedGameState = JSON.parse(savedGameState);

        setUserAnswers(parsedGameState.userAnswers || []);
        setSelectedCategory(parsedGameState.selectedCategory || null);
        setCurrentQuestionIsUnlocked(parsedGameState.currentQuestionIsUnlocked || false);

        setStateDataLoaded(true);
        setShowResumePrompt(false);
        scrollToTop();
    };

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        scrollToTop();
    };

    const handleUnlockQuestion = () => {
        setCurrentQuestionIsUnlocked(true);
        scrollToTop();
    };

    const handleNextQuestion = (selectedAnswerId) => {
        // Save the user's answer
        const updatedUserAnswers = [...userAnswers];
        const selectedAnswer = quizData.Questions[currentQuestionIndex].Answers.find(answer => answer.id === selectedAnswerId);

        updatedUserAnswers.push({
            questionId: quizData.Questions[currentQuestionIndex].id,
            answerId: selectedAnswerId,
            isCorrect: selectedAnswer.isCorrect,

        });
        setUserAnswers(updatedUserAnswers);

        // Move to the next question
        setCurrentQuestionIsUnlocked(false);
        scrollToTop();
    };

    // Let user redo the questions with uncorrect answers
    const handleRedoUncorrect = () => {
        // Remove the user answers where isCorrect is false
        const correctedUserAnswers = userAnswers.filter(answer => answer.isCorrect);
        setUserAnswers(correctedUserAnswers);

        setCurrentQuestionIsUnlocked(false);
        scrollToTop();
    };

    // ---------------------- Render Logic -------------------------

    const renderCurrentView = () => {

        if (showResumePrompt) {
            return (
                <ResumePrompt
                    onContinue={handleContinueFromLocalStorage}
                    onCancel={handleStartOver}
                />
            );
        }

        if (!stateDataLoaded) {
            return <Loader />;
        }

        // If no category has been selected, show category selector
        if (!selectedCategory) {
            return (
                <QuizCategorySelector
                    onSelect={handleCategorySelect}
                />
            );
        }

        // Guard clause for rendering while quiz and location data are loading
        if (!quizData || !quizData.Questions || !locations) {
            return <Loader />;
        }

        // If the quiz is completed (out of questions or locations), show the result
        if (userAnswers.length === quizData.Questions.length || userAnswers.length === locations.length) {
            return (
                <Result
                    questions={quizData.Questions}
                    userAnswers={userAnswers}
                    onTryAgain={handleRedoUncorrect}
                />
            );
        }

        // If the question is unlocked, show the question
        if (currentQuestionIsUnlocked) {
            return (
                <Question
                    questionId={quizData.Questions[currentQuestionIndex].id}
                    questionNumber={quizData.Questions[currentQuestionIndex].number}
                    questionText={quizData.Questions[currentQuestionIndex].question}
                    answers={quizData.Questions[currentQuestionIndex].Answers}
                    onSendAnswer={handleNextQuestion}
                />
            );
        }
        // Else, (if the question is locked), show the unlock view
        else {
            return (
                <QuestionUnlockView
                    questionNumber={quizData.Questions[currentQuestionIndex].number}
                    hint={locations[currentQuestionIndex].hint} //ToDo: add correct hint
                    correctCode={locations[currentQuestionIndex].passcode} //ToDo: add correct code
                    onUnlockQuestion={handleUnlockQuestion}
                />
            );
        }
    };

    // ---------------------- Main Component ------------------------

    return (
        <>
            <div id="quiz-container-and-header-wrapper">
                <Header><InfoButton onClick={toggleModal} /></Header>
                <div id="quiz-container">
                    {renderCurrentView()}
                </div>
                <Footer />
                {isShowing && (
                    <Modal onCloseModal={toggleModal}>
                        <Info />
                    </Modal>
                )}
            </div>
        </>
    );
};

export default Quiz;