import { useState, useEffect } from "react";
import { apiUrl } from '../services/api';

const useTokenValidation = (token) => {
  const [isValid, setIsValid] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      // Skip token validation if the token is empty
      if (token === "") {
        setIsValid(false);
        setTokenExpired(false);
        return;
      }

      // ToDo: Could be moved to admin-api.js
      try {
        const response = await fetch(apiUrl("signIn/validate"), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setIsValid(true);
          setTokenExpired(false);
        } else {
          setIsValid(false);
          setTokenExpired(true);
        }
      } catch (error) {
        setIsValid(false);
        console.error("Error validating token:", error);
      }
    };

    // Call the function to check token validity when component mounts
    checkTokenValidity();

    // Set up a timer to periodically check the token validity every 20 minutes
    const tokenValidityInterval = setInterval(
      checkTokenValidity,
      20 * 60 * 1000
      //10 * 1000 // Test interval
    );

    // Clean up the interval when the component unmounts AKA is removed from the DOM
    return () => clearInterval(tokenValidityInterval);
  }, [token]);

  const handleStayLoggedIn = () => {
    // ToDo: make sure that everything in the form is in a saved state
    // ToDo: After a while in this state the session will run out again. I think this is okay for now

    setTokenExpired(false);
  };

  const handleLogout = () => {
    // To Do: Make sure that data in the form is not saved
    setIsValid(false);
    setTokenExpired(false);
  };

  return {
    isValid,
    tokenExpired,
    handleLogout,
    handleStayLoggedIn,
  };
};

export default useTokenValidation;
