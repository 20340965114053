import React, { useState, useEffect } from "react";
import Quiz from "./Quiz";
import Question from "./QuestionsAndAnswers";
import AdminService from "../../services/admin-api";

const initialDataForNewQuiz = {
  name: "",
  ageCategory: "",
  Questions: [
    {
      number: 1,
      question: "Exempel fråga",
      Answers: [
        {
          id: "1",
          number: 1,
          answer: "Exempel fråga 1",
          isCorrect: true,
        },
        {
          id: "2",
          number: 2,
          answer: "Exempel fråga 2",
          isCorrect: false,
        },
        {
          id: "3",
          number: 3,
          answer: "Exempel fråga 3",
          isCorrect: false,
        },
      ],
    },
  ],
};

const QuizForm = ({
  changeShowForm,
  changeDashboard,
  changeEditForm,
  editForm,
  quizId,
}) => {
  const [quizData, setQuizData] = useState({
    name: "",
    ageCategory: "",
    Questions: [],
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    // Calls API to GET alldata if editForm is true
    if (editForm) {
      const getAllQuizData = async () => {
        let data;
        try {
          data = await AdminService.getQuizData(quizId);
          setQuizData(
            data || {
              name: "",
              ageCategory: "",
              Questions: [],
            }
          );
        } catch (error) {
          console.error("Error getting all quiz data:", error);
        }
      };

      getAllQuizData();
    } else {
      setQuizData(initialDataForNewQuiz);
    }
  }, [editForm]);

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [showError]);

  useEffect(() => {
    if (quizData.id !== "") {
      validateForm(); // Call validateForm only after data has been fetched
    }
  }, [quizData]);

  const onChange = (field, value) => {
    setQuizData({ ...quizData, [field]: value });
  };

  const validateForm = () => {
    // Check if all required fields have values
    const isValid =
      quizData.name.trim() !== "" &&
      quizData.ageCategory.trim() !== "" &&
      quizData.Questions.every(
        (question) =>
          question.question.trim() !== "" &&
          question.Answers.every((answer) => answer.answer.trim() !== "") && // Check if every answer has a non-empty value
          question.Answers.some((answer) => answer.isCorrect) // Check if at least one answer is marked as correct
      );

    // Update the state to reflect the form's validity
    setIsFormValid(isValid);
  };

  /*---------------------- Render logic------------------------*/
  const handleShowForm = () => {
    changeEditForm(false);
    changeShowForm(false);
    changeDashboard(true);
  };
  /*-----------------------------------------------------------*/

  const updateQuizData = async () => {
    try {
      const updateQuizMessage = await AdminService.updateQuiz(
        quizData,
        quizData.isActive
      );
    } catch (error) {
      console.log(error);
    }
  };

  const filterQuestionsAndAnswers = async () => {
    try {
      // Fetch questions from the database
      const questions = await AdminService.getQuestions(quizData.id);

      // Find new questions and by extension answer that don't exist in the database
      const newQuestions = quizData.Questions.filter(
        (q1) => !questions.some((q2) => q1.id === q2.id)
      );

      // Create new questions and answer in the database
      for (const newQuestion of newQuestions) {
        try {
          const createQuestionMessage = await AdminService.createQuestion(
            quizData.id,
            newQuestion
          );

          let number = 1;
          for (const answer of newQuestion.Answers) {
            const createAnswerMessage = await AdminService.createAnswer(
              createQuestionMessage.data.id,
              answer,
              number
            );
            number++;
          }
        } catch (error) {
          console.error(error);
        }
      }

      // Update existing questions and answers
      for (const existingQuestion of questions) {
        const matchedQuestion = quizData.Questions.find(
          (q) => q.id === existingQuestion.id
        );
        if (matchedQuestion) {
          try {
            const updateQuestionMessage = await AdminService.updateQuestion(
              existingQuestion.id,
              matchedQuestion
            );

            for (const answer of matchedQuestion.Answers) {
              const updateAnswerMessage = await AdminService.updateAnswer(
                answer.id,
                answer
              );
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          // Delete the question and its associated answers from the database
          // We must first delete the answers before we can delete the question
          try {
            // Will also delete answers
            const deleteQuestionMessage = await AdminService.deleteQuestion(
              existingQuestion.id
            );
          } catch (error) {
            console.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handlePutSubmit = () => {
    if (isFormValid) {
      const confirmed = window.confirm(`Är du färdig med att redigera rundan?`);
      if (confirmed) {
        filterQuestionsAndAnswers();
        updateQuizData();
        alert("Rundan har blivit uppdaterad!");
      }
    } else {
      setShowError(true); // Show the error message
    }
  };

  const postAllQuizData = async () => {
    try {
      // Create Quiz and save new Quiz id
      const returnQuizData = await AdminService.createQuiz(quizData);

      const newQuizId = returnQuizData.data.id;

      // Create Questions with new Quiz id and save new Question id
      for (const Question of quizData.Questions) {
        const returnQuestionData = await AdminService.createQuestion(
          newQuizId,
          Question
        );
        const newQuestionId = returnQuestionData.data.id;

        // Create Answers with new Question id
        let number = 1;
        for (const Answer of Question.Answers) {
          const returnAnswerData = await AdminService.createAnswer(
            newQuestionId,
            Answer,
            number
          );
          number++;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostSubmit = () => {
    if (isFormValid) {
      const confirmed = window.confirm(`Är du säker att du vill spara rundan?`);
      if (confirmed) {
        postAllQuizData();
        alert("Rundan har blivit sparad!");
      }
    } else {
      setShowError(true); // Show the error message
    }
  };

  const handleActivationButton = async () => {
    try {
      // Toggle the value of isActive
      const newIsActive = !quizData.isActive;

      // Update the quiz with the new value of isActive
      const updatedQuizMessage = await AdminService.updateQuiz(
        quizData,
        newIsActive
      );
      handleShowForm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>
        <div className="button-container">
          {/* Conditionally render the button based on editForm */}
          {editForm && (
            <button
              className="go-back-button"
              onClick={() => handleActivationButton()}
            >
              {quizData.isActive ? "Avaktivera Rundan" : "Aktivera Rundan"}
            </button>
          )}
          <button className="go-back-button" onClick={handleShowForm}>
            Tillbaka
          </button>
        </div>
        <div className="quiz-form-container">
          <h2>
            {editForm ? "Redigera Tipsrunda formulär" : "Ny Tipsrunda formulär"}
          </h2>
          <Quiz data={quizData} onChange={onChange} />
          <Question data={quizData} onChange={onChange} editForm={editForm} />
          <button
            className="large-button"
            onClick={editForm ? handlePutSubmit : handlePostSubmit}
          >
            {editForm ? "Uppdatera Tipsrunda" : "Lägg till ny Tipsrunda"}
          </button>
        </div>
      </div>
      {showError && (
        <div className="alert-container">
          <h2>
            Alla fält måste vara ifyllda innan du kan uppdatera eller spara
            rundan! Kom ihåg att fylla i vilket svar som är sant.
          </h2>
        </div>
      )}
    </div>
  );
};

export default QuizForm;
