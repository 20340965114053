import React from "react";
import logo from "../../assets/logo.png";

const Header = ({ children }) => {
  return (
    <>
      <header className="wave-bottom">
        <div className="header-logo">
          <img src={logo} alt="Logo" id="logo-image" />
          <h1>Tipsrundan</h1>
        </div>
        {children}
      </header>
    </>
  );
};

export default Header;
