import { useState } from "react";

// ------------- Components ----------------
import Login from "../components/Admin/Login";
import LoginHeader from "../components/Admin/LoginHeader";
import QuizForm from "../components/Admin/QuizForm";
import Dashboard from "../components/Admin/Dashboard";
import ChangePassword from "../components/Admin/ChangePassword";
import ExpiredSession from "../components/Admin/ExpiredSession";
import ChooseImage from "../components/Admin/ChooseImage";
import UserDashboard from "../components/Admin/UserDashboard";
import Location from "../components/Admin/LocationManager";

// ------------ Hooks -------------------
import useTokenValidation from "../hooks/tokenHook";

const Admin = () => {
  const [showDashboard, setShowDashboard] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [chooseImage, setChooseImage] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [showUsersAsAdmin, setShowUsersAsAdmin] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [token, setToken] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [quizId, setQuizId] = useState(null);

  // Using the useTokenValidation hook
  const {
    isValid: isValidToken,
    tokenExpired,
    handleStayLoggedIn,
    handleLogout,
  } = useTokenValidation(token);

  // ---------------------- Event Handlers ---------------------
  const changeToken = () => {
    const storedData = localStorage.getItem("LoggedInUser");
    if (storedData) {
      const accessToken = JSON.parse(storedData).accessToken;
      setToken(accessToken);
    } else {
      handleLogout();
    }
  };

  // Use this logout function to clear all auth data and reset the states
  const logout = () => {
    localStorage.removeItem("LoggedInUser");
    setToken("");
    changeDashboard(true);
    changeShowForm(false);
    changeChangePassword(false);
    changeChooseImage(false);
  };

  const changeDashboard = (setVariable) => {
    setShowDashboard(setVariable);
  };

  const changeShowForm = (setVariable) => {
    setShowForm(setVariable);
  };

  const changeChooseImage = (setVariable) => {
    setChooseImage(setVariable);
  };

  const changeChangePassword = (setVariable) => {
    setChangePassword(setVariable);
  };

  const changeEditForm = (setVariable) => {
    setEditForm(setVariable);
  };

  const changeQuizId = (setVariable) => {
    setQuizId(setVariable);
  };

  const changeShowUsersAsAdmin = (setVariable) => {
    setShowUsersAsAdmin(setVariable);
  };

  const changeShowLocation = (setVariable) => {
    setShowLocation(setVariable);
  };

  const handleGoBackFromUser = () => {
    setShowUsersAsAdmin(false);
    setShowDashboard(true);
  };

  const handleGoBackFromLocation = () => {
    setShowLocation(false);
    setShowDashboard(true);
  };

  // ---------------------- Render Logic -------------------------
  const renderCurrentView = () => {
    if (tokenExpired) {
      return (
        <div>
          <ExpiredSession
            handleStayLoggedIn={handleStayLoggedIn}
            logout={logout}
          />
        </div>
      );
    }

    if (isValidToken && showDashboard) {
      return (
        <div>
          <LoginHeader
            logout={logout}
            changeDashboard={changeDashboard}
            changeShowForm={changeShowForm}
            changeChangePassword={changeChangePassword}
            changeShowUsersAsAdmin={changeShowUsersAsAdmin}
          />
          <>
            <Dashboard
              changeShowForm={changeShowForm}
              changeDashboard={changeDashboard}
              changeChooseImage={changeChooseImage}
              changeChangePassword={changeChangePassword}
              changeEditForm={changeEditForm}
              changeQuizId={changeQuizId}
              changeShowLocation={changeShowLocation}
            />
          </>
        </div>
      );
    }
    if (isValidToken && changePassword) {
      return (
        <div>
          <LoginHeader
            logout={logout}
            changeDashboard={changeDashboard}
            changeShowForm={changeShowForm}
            changeChangePassword={changeChangePassword}
            changeShowUsersAsAdmin={changeShowUsersAsAdmin}
          />
          <>
            <ChangePassword
              changeDashboard={changeDashboard}
              changeChangePassword={changeChangePassword}
            />
          </>
        </div>
      );
    }
    if (isValidToken && showForm) {
      return (
        <div>
          <LoginHeader
            logout={logout}
            changeDashboard={changeDashboard}
            changeShowForm={changeShowForm}
            changeChangePassword={changeChangePassword}
            changeShowUsersAsAdmin={changeShowUsersAsAdmin}
          />
          <QuizForm
            changeShowForm={changeShowForm}
            changeDashboard={changeDashboard}
            changeEditForm={changeEditForm}
            editForm={editForm}
            quizId={quizId}
          />
        </div>
      );
    }
    if (isValidToken && chooseImage) {
      return (
        <div>
          <LoginHeader
            logout={logout}
            changeDashboard={changeDashboard}
            changeShowForm={changeShowForm}
            changeChangePassword={changeChangePassword}
            changeShowUsersAsAdmin={changeShowUsersAsAdmin}
          />
          <ChooseImage
            changeChangePassword={changeChangePassword}
            changeDashboard={changeDashboard}
            changeChooseImage={changeChooseImage}
          />
        </div>
      );
    }
    if (isValidToken && showUsersAsAdmin) {
      return (
        <div>
          <LoginHeader
            logout={logout}
            changeDashboard={changeDashboard}
            changeShowForm={changeShowForm}
            changeChangePassword={changeChangePassword}
            changeShowUsersAsAdmin={changeShowUsersAsAdmin}
          />
          <UserDashboard handleGoBack={handleGoBackFromUser} />
        </div>
      );
    }
    if (isValidToken && showLocation) {
      return (
        <div>
          <LoginHeader
            logout={logout}
            changeDashboard={changeDashboard}
            changeShowForm={changeShowForm}
            changeChangePassword={changeChangePassword}
            changeShowUsersAsAdmin={changeShowUsersAsAdmin}
          />
          {/*Lägg hit nya location Felicia!*/}
          <Location handleGoBack={handleGoBackFromLocation} />
        </div>
      );
    } else {
      return (
        <div>
          <header className="wave-bottom"></header>
          <>
            <Login changeToken={changeToken} />
          </>
        </div>
      );
    }
  };

  // ---------------------- Main Component ------------------------
  return (
    <>
      <style>
        {`body{background-color: #b0dde7;}`}
      </style>
      <div id="login-container">{renderCurrentView()}</div>
    </>
  );
};

export default Admin;
