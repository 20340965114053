import React from 'react';
import logo from '../../assets/Eckerolinjen-logo-original-rgb-full.png';

const Header = ({ children }) => {
    return (
        <>
            <div id="footer">
                <img src={logo} alt="Logo" id="footer-logo-image" />
                &copy; Eckerö Linjen 2024
                {children}
            </div>
        </>
    );
};

export default Header;