import React, { useState } from 'react';
import Answer from './Answer';
import ActionButton from './ActionButton';
import QuestionImage from './QuestionImage';

const Question = ({ questionId, questionNumber, questionText, answers, onSendAnswer }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const selectAnswer = (answerId) => {
    setSelectedAnswer(answerId);
  };

  const handleSendAnswer = () => {
    if (selectedAnswer !== null) {
      onSendAnswer(selectedAnswer);
    }
  };

  // Get Tipsrunda style numbers when answer list is 3 in length.
  const getDisplayNumber = (number) => {
    if (answers.length !== 3) {
      return number.toString();
    }

    if (number === 2) {
      return "X";
    } else if (number === 3) {
      return "2";
    }
    return number.toString();
  };

  return (
    <>
      <div className="question">
        <h2 className="question-number">Fråga {questionNumber}</h2>
        <QuestionImage questionId={questionId} />
        <p>{questionText}</p>
      </div>
      <div id="answers-container">
        {answers.map((answer) => (
          <Answer
            key={answer.id}
            answerId={answer.id}
            number={getDisplayNumber(answer.number)}
            text={answer.answer}
            selected={selectedAnswer === answer.id}
            onSelectAnswer={selectAnswer}
          />
        ))}
      </div>
      <ActionButton onClick={handleSendAnswer} disabled={selectedAnswer === null}>
        Svara
      </ActionButton>
    </>
  );
};

export default Question;