import React, { useState, useEffect } from "react";
import AuthService from "../../services/admin-api";

const Dashboard = ({
  changeShowForm,
  changeDashboard,
  changeChooseImage,
  changeEditForm,
  changeQuizId,
  changeShowLocation,
}) => {
  const [alert, setAlert] = useState(false);
  const [activeQuizzes, setActiveQuizzes] = useState([]);
  const [inactiveQuizzes, setInactiveQuizzes] = useState([]);

  // Getting Quizzes from API and seperating them into active and not active
  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const data = await AuthService.getAllQuizzes();
        const active = data.filter((quiz) => quiz.isActive);
        const inactive = data.filter((quiz) => !quiz.isActive);
        setActiveQuizzes(active);
        setInactiveQuizzes(inactive);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuizzes();
  }, [alert]);

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      `Är du säker på att du vill radera rundan?`
    );
    if (confirmed) {
      try {
        const data = await AuthService.deleteQuiz(id);
        setAlert(true);
      } catch (error) {
        console.error("Error deleting quiz:", error);
      }
    }
  };

  const handleEdit = (id) => {
    changeDashboard(false);
    changeShowForm(true);
    changeEditForm(true);
    changeQuizId(id);
  };

  const handleNewQuiz = () => {
    changeDashboard(false);
    changeShowForm(true);
  };

  const handleChooseImage = () => {
    changeDashboard(false);
    changeChooseImage(true);
  };

  const handleShowLocation = () => {
    changeDashboard(false);
    changeShowLocation(true);
  };

  // Handle alert state changes after a delay
  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => {
        setAlert(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [alert]);

  return (
    <>
      <div className="button-container">
        <button className="new-quiz-button" onClick={handleShowLocation}>
          Ändra ledtrådar och platser
        </button>
      </div>
      <div className="button-container">
        <button className="new-quiz-button" onClick={handleChooseImage}>
          Ändra säsongsbilden på startsidan
        </button>
      </div>

      <div className="button-container">
        <button className="new-quiz-button" onClick={handleNewQuiz}>
          + Skapa en ny runda
        </button>
      </div>
      <div className="page-container">
        <div className="quiz-list">
          <h2>Aktiva Rundor</h2>
          <table>
            <tbody>
              {activeQuizzes.map((quiz) => (
                <tr key={quiz.id}>
                  <td className="td-color">
                    {quiz.name + " " + quiz.ageCategory + " år"}
                  </td>
                  <td className="td-transparent">
                    <button
                      className="edit-del-button"
                      onClick={() => handleEdit(quiz.id)}
                    >
                      Editera
                    </button>
                  </td>
                  <td className="td-transparent">
                    <button
                      className="edit-del-button"
                      onClick={() => handleDelete(quiz.id)}
                    >
                      Radera
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="quiz-list">
          <h2>Avaktiverade Rundor</h2>
          <table>
            <tbody>
              {inactiveQuizzes.map((quiz) => (
                <tr key={quiz.id}>
                  <td className="td-color">
                    {quiz.name + " " + quiz.ageCategory + " år"}
                  </td>
                  <td className="td-transparent">
                    <button
                      className="edit-del-button"
                      onClick={() => handleEdit(quiz.id)}
                    >
                      Editera
                    </button>
                  </td>
                  <td className="td-transparent">
                    <button
                      className="edit-del-button"
                      onClick={() => handleDelete(quiz.id)}
                    >
                      Radera
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {alert && (
        <div className="alert-container">
          <h2> Rundan raderas...</h2>
        </div>
      )}
    </>
  );
};
/*  */
export default Dashboard;
