import React from 'react';
import Hint from './Hint';
import SecretCodeInput from './SecretCodeInput';

const QuestionUnlockView = ({ onUnlockQuestion, correctCode, hint, questionNumber }) => {

    const handleUnlock = () => {
        onUnlockQuestion();
    };

    return (
        <>
            <Hint hint={hint}></Hint>
            <h3>Skriv in den hemliga koden från fråga {questionNumber}:</h3>
            <SecretCodeInput
                onCodeSubmit={handleUnlock}
                correctCode={correctCode}
            />
        </>
    );
};

export default QuestionUnlockView;