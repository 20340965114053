import React, { useEffect, useState } from 'react';
import ActionButton from './ActionButton';
import ResultNumberCircle from './ResultNumberCircle';
import { apiUrl } from '../../services/api';


const Result = ({ userAnswers, questions, onTryAgain }) => {
    const [allAnswersCorrect, setAllAnswersCorrect] = useState(null);

    const imageUrl = apiUrl(`homepage-image`);

    useEffect(() => {
        // Check if all answers are correct
        const areAllAnswersCorrect = () => {
            return userAnswers.every(answer => answer.isCorrect);
        };

        // Set the state based on the result of the check
        setAllAnswersCorrect(areAllAnswersCorrect());
    }, [userAnswers]);

    const renderResultNumberCircles = () => {
        return questions
            .filter(question => userAnswers.some(answer => answer.questionId === question.id))
            .map((question, index) => {
                const userAnswer = userAnswers.find(answer => answer.questionId === question.id);
                return (
                    <ResultNumberCircle
                        key={index}
                        number={index + 1}
                        isCorrect={userAnswer ? userAnswer.isCorrect : false}
                    />
                );
            });
    };

    return (
        <>
            {allAnswersCorrect !== null && (
                <>
                    {allAnswersCorrect ? (
                        <>
                            <h1>Bra jobbat!</h1>
                            <img
                                id="question-image"
                                src={imageUrl}
                                alt="Tipsrundan"
                                onError={(e) => e.target.style.display = 'none'}
                            />
                            <h2>Du fick alla rätt!</h2>
                            <h3>Visa denna sida till personalen i informationen för att hämta ditt pris.</h3>
                        </>
                    ) : (
                        <>
                            <h1>Ojdå! Du fick några fel!</h1>
                            <div className="result-numbers-container">
                                {renderResultNumberCircles()}
                            </div>
                            <h2>Gå tillbaka och försök igen med de frågor du svarade fel på.</h2>
                            <ActionButton onClick={onTryAgain}>Försök igen!</ActionButton>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Result;
