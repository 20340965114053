import { apiUrl } from '../../services/api';

const QuestionImage = ({ questionId }) => {

    const imageUrl = apiUrl(`questions/${questionId}/image`);

    // This component hides the image if an error occurs while loading.
    // Consider changing the implementation if handling errors differently is desired;
    // for example, when the image exists but a network issue prevents it from loading.
    return (
        <img
            id="question-image"
            src={imageUrl}
            alt="Question"
            onError={(e) => e.target.style.display = 'none'}
        />
    );
};

export default QuestionImage;
