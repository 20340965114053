import React from 'react';

const ActionButton = ({ onClick, disabled, children }) => {
  return (
    <button className="action-button" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default ActionButton;