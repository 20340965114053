import React, { useState, useEffect } from "react";
import AuthService from "../../services/admin-api";
import Loader from "../Loader/Loader"

const UserDashboardList = ({ onEditPassword }) => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch users data from the server when the component mounts
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const data = await AuthService.getAllUsers();
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
            setError("Ett fel inträffade, försök igen senare.");
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleChangePasswordClicked = (userId, username) => {
        onEditPassword({ userId, username });
    }

    const handleDeleteUser = async (userId, username) => {
        const confirmed = window.confirm(`Är du säker på att du vill radera användaren ${username}?`);
        if (!confirmed) return;

        try {
            const data = await AuthService.deleteUser(userId);
            fetchUsers();
        } catch (error) {
            console.error("Error deleting user:", error);
            setError("Ett fel inträffade, försök igen senare.");
        }
    };

    if (isLoading) {
        return (<div className="center-container"><Loader /></div>)
    }

    if (error) {
        return (
            <div className="error-message" style={{ marginTop: "20px" }}>{error}</div>
        );
    }

    return (
        <>
            {users.filter(user => user.username !== "Admin").length === 0 ? (
                <p>Det finns inte några registrerade användare.</p>
            ) : (
                <table className="user-table">
                    <tbody>
                        {users.filter(user => user.username !== "Admin").map((user) => (
                            <tr key={user.id}>
                                <td className="td-color">{user.username}</td>
                                <td className="td-transparent">
                                    <button className="small-button" onClick={() => handleChangePasswordClicked(user.id, user.username)}>Ändra lösenord</button>
                                </td>
                                <td className="td-transparent">
                                    <button className="small-button" onClick={() => handleDeleteUser(user.id, user.username)}>Radera</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};
export default UserDashboardList;