import React, { useState } from "react";
import UserDashboardList from "./UserDashboardList";
import UserNewForm from "./UserNewForm";
import UserEditPasswordAdmin from "./UserEditPasswordAdmin";

const UserDashboard = ({ handleGoBack }) => {
    const [currentView, setCurrentView] = useState("list");
    const [usernameToEdit, setUsernameToEdit] = useState("");
    const [idToEdit, setIdToEdit] = useState("");

    const handleEditPassword = ({ userId, username }) => {
        setCurrentView("editPassword");
        setUsernameToEdit(username); // For edit password
        setIdToEdit(userId); // For edit password
    };

    const handleNewUserClicked = () => {
        setCurrentView("newUser");
    };

    return (
        <div>
            {currentView === "list" && (
                <>
                    <div className="button-container">
                        <button className="go-back-button" onClick={handleGoBack}>
                            Tillbaka
                        </button>
                    </div>
                    <div className="center-container">
                        <div className="user-dashboard">
                            <h2>Användare</h2>
                            <button className="small-button" onClick={handleNewUserClicked}>+ Lägg till användare</button>
                            <UserDashboardList onEditPassword={handleEditPassword} />
                        </div>
                    </div>
                </>
            )}
            {currentView === "editPassword" && (
                <UserEditPasswordAdmin
                    onGoBack={() => setCurrentView("list")}
                    username={usernameToEdit}
                    id={idToEdit}
                />
            )}
            {currentView === "newUser" && <UserNewForm onGoBack={() => setCurrentView("list")} />}
        </div>
    );
};

export default UserDashboard;