import React, { useState } from "react";

const Quiz = ({ data, onChange }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <h3 onClick={toggleExpand}>{expanded ? "v" : ">"} Runda </h3>
      {expanded && (
        <div className="quiz-container">
          <div className="input-container">
            <label htmlFor="name">Namn</label>
            <input
              type="text"
              id="name"
              name="name"
              value={data.name}
              onChange={handleChange}
            />
          </div>
          <div className="input-container">
            <label htmlFor="ageCategory">
              Åldersgrupp (t.ex 10 eller 10-14)
            </label>
            <input
              type="text"
              id="ageCategory"
              name="ageCategory"
              value={data.ageCategory}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
