import axios from "axios";
import { apiUrl } from './api';

const getAccessToken = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("LoggedInUser")) ?? {};
  const accessToken = loggedInUser.accessToken;
  return accessToken;
};

/* -------- GET ---------- */

const getLocations = async () => {
  try {
    const response = await axios.get(apiUrl("locations"));
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const getAllQuizzes = async () => {
  try {
    const response = await axios.get(apiUrl("quizzes"));
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const getQuestions = async (id) => {
  try {
    const response = await axios.get(apiUrl(`questions/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const getQuestion = async (quizId, number) => {
  try {
    const response = await axios.get(
        apiUrl(`/questions/${quizId}/${number}`),
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const getAnswers = async (questionId) => {
  try {
    const response = await axios.get(
        apiUrl(`/answers/by-question/${questionId}`),
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const getQuizData = async (id) => {
  try {
    const response = await axios.get(apiUrl(`quizzes/${id}/alldata`), {});
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const getAllUsers = async () => {
  try {
    const response = await axios.get(apiUrl(`users`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

/* const getLocationData = async (id) => {
  try {
    const response = await axios.get(apiUrl(`locations/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
}; */

/* -------- POST ---------- */

const signUp = async (username, password) => {
  try {
    const response = await axios.post(apiUrl("signUp"), {
      username: username,
      password: password,
    },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const signIn = async (username, password) => {
  const response = await axios.post(apiUrl("signIn"), {
    username,
    password,
  });
  if (response.data.accessToken) {
    localStorage.setItem("LoggedInUser", JSON.stringify(response.data));
  }
  return response.data;
};

const createQuiz = async (quizData) => {
  try {
    const response = await axios.post(
        apiUrl(`/quizzes`),
      {
        name: quizData.name,
        ageCategory: quizData.ageCategory,
        isActive: false, // New quizzes is always false
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const createQuestion = async (id, questionData) => {
  try {
    const response = await axios.post(
        apiUrl(`/questions/${id}`),
      {
        question: questionData.question,
        number: questionData.number,
        quizId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const createAnswer = async (questionId, answerData, number) => {
  try {
    const response = await axios.post(
        apiUrl(`answers/${questionId}`),
      {
        questionId: questionId,
        number: number,
        answer: answerData.answer,
        isCorrect: answerData.isCorrect,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const createLocation = async (locationData, number) => {
  try {
    const response = await axios.post(
        apiUrl(`locations/`),
      {
        number: number,
        passcode: locationData.passcode,
        hint: locationData.hint,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

/* -------- PUT ---------- */

const updateQuiz = async (quizData, isActive) => {
  try {
    const response = await axios.put(
      apiUrl(`quizzes/${quizData.id}`),
      {
        name: quizData.name,
        isActive: isActive,
        ageCategory: quizData.ageCategory,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const updateQuestion = async (id, answerData) => {
  try {
    const response = await axios.put(
      apiUrl(`questions/${id}`),
      { question: answerData.question, number: answerData.number },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const updateAnswer = async (id, answerData) => {
  try {
    const response = await axios.put(
      apiUrl(`answers/${id}`),
      {
        answer: answerData.answer,
        isCorrect: answerData.isCorrect,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const updateUserPassword = async (oldPassword, newPassword) => {
  const accessToken = getAccessToken();

  try {
    const response = await axios.put(
        apiUrl(`users/change-password/self`),
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // This throws the error to be handled in the component
    throw error;
  }
};

const updateLocation = async (id, locationData) => {
  try {
    const response = await axios.put(
        apiUrl(`/locations/${id}`),
      {
        number: locationData.number,
        passcode: locationData.passcode,
        hint: locationData.hint,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

/* -------- DEL ---------- */

const deleteQuiz = async (id) => {
  try {
    const response = await axios.delete(apiUrl(`quizzes/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(apiUrl(`users/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const deleteQuestion = async (id) => {
  try {
    const response = await axios.delete(apiUrl(`questions/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const deleteAnswer = async (id) => {
  try {
    const response = await axios.delete(apiUrl(`answers/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const deleteLocation = async (id) => {
  try {
    const response = await axios.delete(apiUrl(`locations/${id}`), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error; // To Do: Display error?
  }
};

const AdminService = {
  // GET
  getLocations,
  getAllQuizzes,
  getQuestions,
  getQuizData,
  getAnswers,
  getAllUsers,
  getQuestion,
  // POST
  createQuiz,
  createQuestion,
  createAnswer,
  signIn,
  signUp,
  createLocation,
  // PUT
  updateQuiz,
  updateQuestion,
  updateAnswer,
  updateUserPassword,
  updateLocation,
  // DEL
  deleteQuiz,
  deleteUser,
  deleteQuestion,
  deleteAnswer,
  deleteLocation,
};

export default AdminService;
