import { React, useState } from "react";
import AdminService from "../../services/admin-api";

const ChangePassword = ({ changeDashboard, changeChangePassword }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState(""); // To store the success or error message
  const [isError, setIsError] = useState(false); // To determine the type of message

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset status message on new submission
    setStatusMessage("");
    setIsError(false);

    // Validation logic
    if (newPassword !== confirmPassword) {
      setStatusMessage("Lösenord matchar ej");
      setIsError(true);
      return;
    }
    if (newPassword.length < 8 || confirmPassword.length < 8) {
      setStatusMessage("Lösenordet måste innehålla minst 8 karaktärer");
      setIsError(true);
      return;
    }

    try {
      await AdminService.updateUserPassword(
        oldPassword,
        newPassword
      );
      setStatusMessage("Lösenordet har ändrats");
    } catch (error) {
      setStatusMessage(error.response?.data?.message || error.message);
      setIsError(true);
    }
    /*
    console.log(
      "Password change submitted:",
      oldPassword,
      newPassword,
      confirmPassword
    );
    */
  };

  const handleGoBack = () => {
    changeDashboard(true);
    changeChangePassword(false);
  };

  return (
    <>
      <div className="button-container">
        <button className="go-back-button" onClick={handleGoBack}>
          Tillbaka
        </button>
      </div>
      <div className="form-container">
        <h2>Byt Lösenord</h2>
        {statusMessage && (
          <div className={isError ? "error-message" : "success-message"}>
            {statusMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group-1">
            <div className="text">Gammalt lösenord</div>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="text">Nytt lösenord</div>
          <div>
            <input
              type="password"
              minLength="8" required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group-2">
            <div className="text">Upprepa nytt lösenord</div>
            <input
              type="password"
              minLength="8" required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <button className="large-button" type="submit">
            Ändra lösenord
          </button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
