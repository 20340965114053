import React, { useState, useEffect } from "react";
import AuthService from "../../services/admin-api";
import FormInputField from "./FormInputField";

const UserNewForm = ({ onGoBack }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [responseError, setResponseError] = useState("");

  useEffect(() => {
    // Fetch users data from the server when the component mounts
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await AuthService.getAllUsers();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const validateForm = () => {
    const errors = {};

    const lowercaseUsername = username.trim().toLowerCase();

    if (!lowercaseUsername) {
      errors.username = "Användarnamn krävs.";
    } else if (users.some(user => user.username.toLowerCase() === lowercaseUsername)) {
      errors.username = "Användarnamnet används redan.";
    } else if (lowercaseUsername.length > 50) {
      errors.username = "Användarnamnet får vara max 50 tecken.";
    }

    if (!password.trim()) {
      errors.password = "Lösenordet kan inte vara tomt.";
    } else if (password.length < 8) {
      errors.password = "Lösenordet måste vara minst 8 tecken långt.";
    } else if (password.length > 128) {
      errors.password = "Lösenordet får vara max 128 tecken.";
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = "Lösenorden stämmer inte överens.";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await AuthService.signUp(username, password);
      setSuccessMessage("Användaren har lagts till.");
    } catch (error) {
      console.error("Ett fel inträffade:", error);
      setResponseError(error);
    }
  };

  return (
    <>
      <div className="button-container">
        <button className="go-back-button" onClick={onGoBack}>
          Tillbaka
        </button>
      </div>
      <div className="center-container">
        {successMessage ? (
          <div className="success-message">{successMessage}</div>
        ) : (
          <div className="form-container user-form-container">
            <h2>Lägg till ny användare:</h2>
            <FormInputField
              label="Användarnamn"
              value={username}
              onChange={setUsername}
              error={formErrors.username}
            />
            <FormInputField
              label="Lösenord"
              type="password"
              value={password}
              onChange={setPassword}
              error={formErrors.password}
            />
            <FormInputField
              label="Upprepa lösenord"
              type="password"
              value={confirmPassword}
              onChange={setConfirmPassword}
              error={formErrors.confirmPassword}
            />
            {responseError && <div className="error-message">Ett fel inträffade. Försök igen senare.</div>}
            <button className="large-button" type="submit" onClick={handleSubmit}>
              Lägg till användare
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UserNewForm;